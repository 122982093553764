/* eslint-disable camelcase */
import auth0Roles from 'constants/authorization';
import { CPOHUB, DEMO, GENIE_2, OMED } from 'constants/companies';
import { fieldVisibilityTypes, populationVisibilityTypes } from 'constants/users';

import { UserInfo } from 'context/UserProvider';

import { UserAnalyticsProfile } from 'api/userApi';

interface UserOrganization {
  name: string;
  display_name: string;
}

type CompanyMetadata = {
  field_visibility: string;
  nodes_visible: string[];
  population_visibility: string;
  hris_employee_id: string;
};

interface UserAppMetadata {
  admin: {
    staff: boolean;
    superadmin: boolean;
  };
  config: {
    multiple_orgs: boolean;
  };
  [companyKey: string]:
    | {
        field_visibility: string;
        nodes_visible: string[];
        population_visibility: string;
        hris_employee_id: string;
      }
    | { staff: boolean; superadmin: boolean }
    | { multiple_orgs: boolean };
}

type UserAppRoles = Array<string>;

const getCustomClaim = (user: UserInfo, claim: string): unknown => {
  return user?.[`https://knoetic.com/${claim}`];
};

export const getUserCompany = (user: UserInfo) => {
  return (getCustomClaim(user, 'organization') as UserOrganization)?.name;
};

export const getUserCompanyDisplayName = (user: UserInfo) => {
  return (getCustomClaim(user, 'organization') as UserOrganization)?.display_name;
};

export const getUserNodesVisible = (user: UserInfo) => {
  const userCompany = getUserCompany(user);
  const appMetadata = getCustomClaim(user, 'app_metadata') as UserAppMetadata;
  const companyMetadata = appMetadata[userCompany] as CompanyMetadata;
  const nodes =
    companyMetadata?.population_visibility === populationVisibilityTypes.RESTRICTED_SELF.key
      ? [companyMetadata?.hris_employee_id]
      : companyMetadata?.nodes_visible;
  return nodes;
};

// TODO: Rename to userIsStaff to prevent confusion with orgAdmins
export const userIsAdmin = (user: UserInfo) => {
  return (getCustomClaim(user, 'app_metadata') as UserAppMetadata)?.admin?.staff;
};

export const userIsSuperAdmin = (user: UserInfo) => {
  return !!(getCustomClaim(user, 'app_metadata') as UserAppMetadata)?.admin?.superadmin;
};

export const userIsGuest = (user: UserInfo) => {
  const roles = getCustomClaim(user, 'roles') as UserAppRoles;
  return (
    roles.includes(auth0Roles.ORG_GUEST_USER) &&
    !roles.includes(auth0Roles.ORG_NORMAL_USER) &&
    !roles.includes(auth0Roles.ORG_ADMIN_USER)
  );
};

export const userIsForumAdmin = (user: UserInfo) => {
  const roles = (getCustomClaim(user, 'roles') as UserAppRoles) || [];
  return roles.includes(auth0Roles.CPOHQ_FORUM_ADMIN);
};

export const userIsOrgAdmin = (user: UserInfo) => {
  const roles = (getCustomClaim(user, 'roles') as UserAppRoles) || [];
  return roles.includes(auth0Roles.ORG_ADMIN_USER);
};

export const userHasAccessToAnalytics = (user: UserInfo) => {
  const roles = (getCustomClaim(user, 'roles') as UserAppRoles) || [];
  return (
    roles.includes(auth0Roles.ORG_GUEST_USER) ||
    roles.includes(auth0Roles.ORG_ADMIN_USER) ||
    roles.includes(auth0Roles.ORG_NORMAL_USER) ||
    userIsSuperAdmin(user)
  );
};

export const userHasAccessToCpoHub = (user: UserInfo) => {
  const roles = (getCustomClaim(user, 'roles') as UserAppRoles) || [];
  return roles.includes(auth0Roles.CPOHQ_MEMBER || auth0Roles.CPOHQ_FORUM_ADMIN);
};

export const userHasAccessToGenie2 = (user: UserInfo) => {
  const roles = (getCustomClaim(user, 'roles') as UserAppRoles) || [];
  return roles.includes(auth0Roles.GENIE_2_USER);
};

export const isCpoHqOrganization = (user: UserInfo) => {
  return getUserCompany(user) === CPOHUB;
};

export const isDemo = (user: UserInfo) => {
  return getUserCompany(user) === DEMO || getUserCompany(user) === OMED;
};

export const isGenie2 = (user: UserInfo) => {
  return getUserCompany(user) === GENIE_2;
};

export const getUserCompanyLogoUrl = (user: UserInfo) => {
  const companyName = getUserCompany(user);
  if (!companyName) {
    return null;
  }

  return `https://s3.amazonaws.com/twine-labs-static-files/client-logos/${companyName}_horizontal.png`;
};

// Using UserAnalyticsProfile from Optica

export const userHasFieldVisibilityRestrictions = (analyticsProfile: UserAnalyticsProfile) => {
  return analyticsProfile?.field_visibility_type === fieldVisibilityTypes.RBAC.key;
};

export const userHasPopulationVisibilityRestrictions = (analyticsProfile: UserAnalyticsProfile | UserInfo) => {
  return (
    analyticsProfile?.population_visibility_type !== populationVisibilityTypes.FULL.key &&
    analyticsProfile.company !== CPOHUB
  );
};

export const userHasMultipleOrganizations = (user: UserInfo) => {
  return userIsAdmin(user) || (getCustomClaim(user, 'app_metadata') as UserAppMetadata)?.config?.multiple_orgs;
};
