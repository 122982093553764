import React from 'react';
import { Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

import client from 'queries/apolloClient';

import { initFullStory } from 'utils/fullstoryAnalytics/fullstory';

import App from 'App';
import {
  API_ROOT,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  LAUNCHDARKLY_CLIENT_ID,
  getEnvironment,
  environments,
  PROD_API_ENDPOINT,
} from 'config';
import { ADMIN_ROUTE_NAME, CPOHQ_ROUTE_NAME, PREFERENCES_ROUTE_NAME } from 'routeMap';

const history = createBrowserHistory();
const environment = getEnvironment();

if ([environments.PRODUCTION, environments.STAGING].includes(environment)) {
  const integrations =
    environment === environments.PRODUCTION
      ? [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            tracingOrigins: [PROD_API_ENDPOINT],
            beforeNavigate: (context) => {
              if (
                [
                  'login/callback',
                  'keydrop',
                  'reports',
                  ADMIN_ROUTE_NAME,
                  CPOHQ_ROUTE_NAME,
                  PREFERENCES_ROUTE_NAME,
                ].some((excludedUrl) => window.location.pathname.includes(excludedUrl))
              ) {
                return undefined;
              }
              return context;
            },
            maxTransactionDuration: 0,
            idleTimeout: 2000,
          }),
          new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            networkDetailAllowUrls: [window.location.origin, /^https:\/\/api\.twinelabs\.dev/],
          }),
        ]
      : [];

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations,
    environment,
    tracesSampleRate: 1,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCHDARKLY_CLIENT_ID,
  });

  initFullStory();

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <LDProvider>
      <ApolloProvider client={client}>
        <Router history={history}>
          <Auth0Provider
            useRefreshTokens
            useRefreshTokensFallback
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: API_ROOT,
            }}
            onRedirectCallback={(state) => window.location.replace(state?.returnTo || window.location.pathname)}
          >
            <App />
          </Auth0Provider>
        </Router>
      </ApolloProvider>
    </LDProvider>,
  );
})();
